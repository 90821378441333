import React, { useEffect } from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import { blogs } from "../data/blog";
import BlogTitles from "../components/BlogTitles";
import { Link, useLocation } from "react-router-dom";
import { page } from "../data/pages";
import "../styles/blog.css";
const Blogs = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="blogs">
      <Navbar />
      <div className="container-titles">
        <div className="blog" id="blog">
          <h2>Последните ни статии в блога</h2>
          <div className="blog-content">
            {blogs.map((item, id) => (
              <Link
                key={id}
                to={`/blog/${id}`}
                state={{ data: page[id] }}
                className="links"
              >
                <BlogTitles
                  image={item.image}
                  title={item.title}
                  author={item.author}
                  date={item.date}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
