import React from "react";
import { BiUser } from "react-icons/bi";
import { RxCalendar } from "react-icons/rx";
const BlogCard = ({ image, title, article, author, date }) => {
  return (
    <div className="blog-card">
      <img src={image} alt="" />
      <div className="content">
        <h3>{title}</h3>
        <p>{article}</p>
        <span>5 min read</span>
      </div>
      <div className="blog-author">
        <div className="blog-author-name">
          <BiUser size={20} color="#3ecf8e" />
          <span>{author}</span>
        </div>
        <div className="blog-author-date">
          <RxCalendar size={20} color="#3ecf8e" />
          <span>{date}</span>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
