import React from "react";
import "../styles/blog.css";
import { blogs } from "../data/blog";
import BlogCard from "../components/BlogCard";
import { Link } from "react-router-dom";
import { page } from "../data/pages";
import { scrollTop } from "../utils/scroll";
import { Scroll } from "../pages/Main";
const Blog = () => {
  return (
    <div className="container">
      <div className="blog" id="blog">
        <h2>Последните ни статии в блога</h2>
        <div className="blog-content">
          {blogs.slice(0, 3).map((item, id) => (
            <Link
              key={id}
              to={`/blog/${id}`}
              state={{ data: page[id] }}
              hrefLang=""
            >
              <BlogCard
                image={item.image}
                title={item.title}
                article={item.article}
                author={item.author}
                date={item.date}
              />
            </Link>
          ))}
        </div>
        <Link to={"/blogs"}>
          <button className="btn-blog">ВСИЧКИ СТАТИИ</button>
        </Link>
      </div>
    </div>
  );
};

export default Blog;
