export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollIntoBlog = () => {
  const blog = document.getElementById("blog");
  if (blog) {
    blog.scrollIntoView({block: 'center' , behavior: "smooth" });
  }
};

export const scrollIntoContact = () => {
  const contact = document.getElementById("contact");
  if (contact) {
    contact.scrollIntoView({block:'center', behavior: "smooth" });
  }
};

export const scrollIntoProcess = () => {
  const process = document.getElementById("process");
  if (process) process.scrollIntoView({block:'center', behavior: "smooth" });
};
export const scrollIntoService = () => {
  const service = document.getElementById("services");
  if (service) service.scrollIntoView({block:'start', behavior: "smooth" });
};


export const scrollchange = () =>{
  console.log(window.onscroll);
}