import React, { useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import "../styles/contact.css";

const Contact = () => {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jrq30n3",
        "template_nm7dzye",
        form.current,
        "0eo6DJHoD51iBxLmA"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact" id="contact">
      <h2 className="contact-title">Свържи се с нас</h2>
      <Link to="mailto:support@businesshamali.com">
        <h3 style={{ color: `#3ecf8e` }}>support@businesshamali.com</h3>
      </Link>
      <div className="contact-form">
        <form ref={form} className="form" onSubmit={handleSubmit}>
          <div className="input-field">
            <input
              name="name"
              type="text"
              placeholder="Име"
              className="form-name"
            />
            <input
              name="about"
              type="text"
              placeholder="Относно"
              className="form-about"
            />
            <input
              name="email"
              type="email"
              placeholder="Имейл"
              className="form-email"
            />
            <textarea
              name="description"
              id=""
              rows="3"
              placeholder="Напишете в кратък текст кои от нашите услуги ви интересуват"
              className="form-area"
            ></textarea>
          </div>
          <button className="btn-submit">Свържи се с нас</button>
        </form>
        <div className="contact-img">
          <img src="./img/012.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
