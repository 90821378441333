import React from "react";
import HeroCard from "../components/HeroCard";
import "../styles/herocards.css";
const Process = () => {
  return (
    <div className="container">
      <div className="process" id="process">
        <HeroCard
          title="Първоначална Консултация"
          article="Ще ви предоставим безплатна консултация, в която заедно ще обсъдим вашите процеси и фирмени цели, с цел тяхното оптимизиране и подобряване от гледна точка на сигурност и финансови параметри. "
          number={1}
        />
        <HeroCard
          title="Анализ"
          article="На базата на детайлен анализ на вашите бизнес процеси и политики за сигурност, ще ви изготвим конкретна стратегия, която да направи вашия бизнес по-успешен, по-сигурен и печеливш. "
          number={2}
        />
        <HeroCard
          title="Резултат"
          article="Базирайки се на нашия дълъг опит в различни сфери, както и използвайки иновативни статистически методи, ще ви предоставим дългосрочно решение, което ще промени в положителна посока Вашият бизнес "
          number={3}
        />
      </div>
    </div>
  );
};

export default Process;
