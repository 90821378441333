import React from "react";
import "../styles/services.css";
import ServiceCard from "../components/ServiceCard";
import { services } from "../data/services";
const Services = () => {
  return (
    <div style={{ backgroundColor: `#f9f9f9` }}>
      <div className="container" id="services">
        <div className="services">
          <h2 className="service-title">Услуги които предлагаме</h2>
          <div className="service-cards">
            {services.map((item, id) => (
              <ServiceCard
                key={id}
                icon={item.icon}
                title={item.title}
                article={item.article}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
