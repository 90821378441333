import React from "react";

export const TopLayout = ({ children }) => (
  <div className="hero">
    <div className="background-hero">
      <span className="background-small-1"></span>
      <span className="background-small-2"></span>
    </div>
    {children}
  </div>
);
