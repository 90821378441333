import React from "react";
import "../styles/herocards.css";
const HeroCard = ({ title, article, number }) => {
  return (
    <div className="hero-card">
      <div className="hero-card-number">{number}</div>
      <h2 className="hero-card-title">{title}</h2>
      <p className="hero-card-article">{article}</p>
    </div>
  );
};

export default HeroCard;
