import React from "react";
import "../styles/services.css";
const ServiceCard = ({ icon, title, article }) => {
  return (
    <div className="service-card">
      <div className="service-icon">{icon}</div>
      <div className="service-content">
        <h2 className="service-card-title">{title}</h2>
        <p className="service-card-article">{article}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
