import React, { useState } from "react";
import Hero from "../sections/Hero";
import Process from "../sections/Process";
import Services from "../sections/Services";
import Blog from "../sections/Blog";
import Contact from "../sections/Contact";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import { IoIosArrowUp } from "react-icons/io";
import "../App.css";
import { scrollTop } from "../utils/scroll";

const Main = () => {
  const [openNavbar, setOpenNavbar] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <Navbar opened={openNavbar} open={setOpenNavbar} />
      <div className={openNavbar ? "navbar-blur" : ""}>
        <Hero />
        <Process />
        <Services />
        <Blog />
        <Contact />
        <Footer />
        <button className="scroll-top" onClick={() => scrollTop()}>
          <IoIosArrowUp color="white" size={30} />
        </button>
      </div>
    </div>
  );
};

export default Main;
