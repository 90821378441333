import React from "react";
import { Link } from "react-router-dom";
import {
  scrollIntoBlog,
  scrollIntoContact,
  scrollIntoProcess,
  scrollIntoService,
  scrollTop,
} from "../utils/scroll";
import { FiMenu } from "react-icons/fi";
const Navbar = ({ opened, open }) => {
  return (
    <div className="navbar-container">
      <div className="navbar" id="navbar">
        <Link to={"/"}>
          <div className="logo">
            <img width="200px" src="/img/logo-white.png" alt="" />
          </div>
        </Link>
        <ul className={opened ? "open-menu" : "close-menu"}>
          <Link to={"/"}>
            <li
              onClick={() => {
                scrollTop();
                if (opened) {
                  open(false);
                }
              }}
            >
              Начало
            </li>
          </Link>
          <Link to={"/"}>
            <li
              onClick={() => {
                scrollIntoProcess();
                if (opened) {
                  open(false);
                }
              }}
            >
              Процес
            </li>
          </Link>
          <Link to={"/"}>
            <li
              onClick={() => {
                scrollIntoService();
                if (opened) {
                  open(false);
                }
              }}
            >
              Услуги
            </li>
          </Link>
          <Link to={"/"}>
            <li
              onClick={() => {
                scrollIntoBlog();
                if (opened) {
                  open(false);
                }
              }}
            >
              Блог
            </li>
          </Link>
          <Link to={"/"}>
            <li
              onClick={() => {
                scrollIntoContact();
                if (opened) {
                  open(false);
                }
              }}
            >
              Контакти
            </li>
          </Link>
        </ul>
        <div onClick={() => open(!opened)} className="menu">
          <FiMenu />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
