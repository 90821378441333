import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer.css";
import {
  scrollIntoBlog,
  scrollIntoProcess,
  scrollIntoService,
  scrollTop,
} from "../utils/scroll";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-company">
          <div className="company">
            <h2>Компания</h2>
            <ul>
              <li onClick={() => scrollTop()}>За нас</li>
              <li onClick={() => scrollIntoProcess()}>Процес</li>
              <li onClick={() => scrollIntoService()}>Услуги</li>
              <li onClick={() => scrollIntoBlog()}>Блог</li>
            </ul>
          </div>
          <div className="abonament">
            <h2>Абонирай се</h2>
            <div className="abn-cont">
              <p>Абонирай се за нашия блог</p>
              <div className="send-email">
                <input type="text" placeholder="Въведи имейл" />
                <button></button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-logo">
          <div className="logo">
            <Link to={"/"}>
              <div className="logo">
                <img width="200px" src="/img/logo-white.png" alt="" />
              </div>
            </Link>
            Business Hamali © 2023
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
