import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../styles/blog.css";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import { TopLayout } from "../components/TopLayout";
import "../styles/blog.css";
export const Scroll = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const BlogPage = () => {
  let { state } = useLocation();
  let data = state.data;

  const replaceWrite = () => {
    return data.text.replace(/\n/g, "<br />");
  };
  return (
    <>
      <Navbar />
      <Scroll />
      <div className="section-blog-page">
        <div className="blog-page">
          <TopLayout>
            <div className="container-blog">
              <div className="hero-page">
                <h1>{data.title}</h1>
                <img src={data.image} alt="" />
              </div>
            </div>
          </TopLayout>
          <div className="container-blog">
            <p dangerouslySetInnerHTML={{ __html: replaceWrite() }} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPage;
