import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./pages/Main";
import BlogPage from "./pages/BlogPage";
import Blogs from "./pages/Blogs";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/blog/:id",
    element: <BlogPage />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
]);

function App() {
  return (
    <div className="App">
      <div className="main-container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
