import React, { useEffect } from "react";
import { TopLayout } from "../components/TopLayout";

const Hero = () => {
  useEffect(() => {
    const showHeight = () => {
      return window.screenY;
    };
    let thisHeight = showHeight();
    if (thisHeight !== 0) {
      const nav = document.getElementById("navbar");
      nav.style.color = "green";
    }

    window.addEventListener("scroll", showHeight);

    return () => window.removeEventListener("scroll", showHeight);
  }, []);
  return (
    <TopLayout>
      <div className="container">
        <div className="left-hero">
          <p>Корпоративна сигурност и бизнес анализи за всеки успешен бизнес</p>
          {/* <p className="left-hero-prom">
            Възползвайте се от нашата безплатна детайлна консултация с един
            клик.
          </p> */}
          {/* <button className="btn-connect" onClick={() => scrollIntoContact()}>
            Свържи се с нас{" "}
          </button> */}
        </div>
        <div className="right-hero">
          <img src="./img/businessman.jpg" alt="" />
        </div>
      </div>
    </TopLayout>
  );
};

export default Hero;
