import { AiOutlineCoffee } from "react-icons/ai";
import { BsBriefcase } from "react-icons/bs";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FiLayers } from "react-icons/fi";
import { PiArrowsCounterClockwiseDuotone } from "react-icons/pi";
import { VscServerProcess } from "react-icons/vsc";

export const services = [
    {
          icon: <AiOutlineCoffee size={60}/>,
          title:"Корпоративна сигурност",
          article: "Нашият голям опит в областта на корпоративната сигурност включва консултирането на стотици фирми от различни сектори. Заедно с тях успяхме да идентифицираме слабите страни в техните процеси и заплахите произтичащи това, като след това заедно работихме по тяхното пълно отстраняване. "
    },
    {
        icon:<BsBriefcase size={60}/>,
          title:"Автоматизация и оптимизация на бизнес процесите",
          article:" Всеки бизнес страда от липсата на максимално ефективни процеси. Това е резултат от много фактори, като дори и най-добрите организации имат нужда от външна помощ в автоматизирането и оптимизирането на своите настоящи процеси, както и създаването на нови такива. Ние може да ви помогнем да направите това, чрез различни системи за роботизация, статистически анализи и най-вече нашия опит и знания за това. "
    },
    {
        icon:<HiOutlineLightBulb size={60}/>,
          title:"Дигитализация",
          article:"Дигитализацията е бъдещето на всеки бизнес, който иска да бъде конкурентен в този динамичен свят. Това включва миграцията на много процеси към ‘облака’, тяхната автоматизация или използването на изкуствен интелект за различни предвиждания и оптимизации. Ние можем да ви предоставим всичко това."
    },{
        icon:<FiLayers size={60}/>,
          title:"Анализи и прогнозиране",
          article:"Нашият екип използва най-ефективните аналитични методи, за да добавим максимална бизнес стойност към вашите процеси. Това включва различни оптимизационни и методи за предвиждане. "
    },{
        icon:<PiArrowsCounterClockwiseDuotone size={60}/>,
          title:"Изграждане/Оптимизации на бази от данни - Облачни и локални ",
          article:"Имаме дългогодишен опит в изграждането, поддържането и подобряването на различни видове бази данни. Резултат е по-надеждни, по-сигурни и по-бързи операции с вашите данни. Това спестява много разходи на вашия бизнес и увеличава производителността. "
    },{
        icon:<VscServerProcess size={60}/>,
          title:"Изграждане на ETL процеси",
          article:"Ние предлагаме изграждането на ETL(extract, transform, load) процеси, с което помагаме на вашия бизнес да интегрира и управлява своите данни от различни източници. Това включва индивидуално изградени ETL процеси, които ще подобряват ефективността и намалят разходите на вашия бизнес. "
    }
]