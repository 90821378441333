import React from "react";
import { BiUser } from "react-icons/bi";
import { RxCalendar } from "react-icons/rx";
import "../styles/blog.css";
const BlogTitles = ({ image, title, author, date }) => {
  return (
    <div className="container blog-title-container">
      <div className="blog-card-titles">
        <img height="240px" width="360px" src={image} alt="" />
        <div className="content">
          <h3>{title}</h3>
          <span>5 min read</span>
        </div>
        <div className="blog-author">
          <div className="blog-author-name">
            <BiUser size={20} color="#3ecf8e" />
            <span>{author}</span>
          </div>
          <div className="blog-author-date">
            <RxCalendar size={20} color="#3ecf8e" />
            <span>{date}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogTitles;
